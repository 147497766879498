:root {
  --bodybg: #dcdefe;
  --primary-color: #5138ee;
  --grey: #d6d6d6;
  --placeholder: #969696;
  --white: #fff;
  --text: #333;
  --slider-bg: #eff3ff;
  --info-cta-hover: #1f0098;
}
.info-container {
  display: flex;
  max-width: 1200px;
  background: var(--white);
  margin: 0 !important;
  width: 100%;
  font-family: 'Inter var';
}

/*---------- Left side css------------ */

.info-container .onboarding {
  flex: 1;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom left, #f3ebfc 0%, #c2c8fd 100%);
  height: 572px;
}

.information_dialog .MuiDialog-paperFullWidth {
  border-radius: 15px !important;
  height: 572px;
}
.information_dialog .MuiDialog-paperFullWidth::-webkit-scrollbar {
  display: none;
  height: 100%;
}
.pricing-dialog .MuiDialog-paperFullWidth {
  min-height: 572px;
  display: flex;
  padding: 20px;
}
.pricing-page {
  min-height: unset;
  padding: 2rem;
  margin-top: 0;
}

.slide-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  padding: 25px;
}
.info-container .onboarding .slide-image img {
  width: 100%;
  height: 100%;
}

/* --------- End ------- */

/* ------------- Right side css ------------  */
.info-container .info-form {
  width: 50%;
  box-sizing: border-box;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 572px;
}

/* Restaurant and OTP verification form css */
.info-container .info-form .info-form-inner {
  max-width: 450px;
  width: 95%;
}

/* Review Detail Form css */
.info-container .info-form .info-form-inner-small {
  box-shadow: 1px 0px 5px gray;
  width: 90%;
  padding: 10px;
  margin: 0px 30px;
  border-radius: 10px;
}

/* Custom form css */
.custom-form {
  height: 572px;
  max-width: 550px;
  width: 95%;
}

/* -------Form input css---------- */
.info-container .info-form .info-form-group {
  position: relative;
  margin-bottom: 20px;
}
.info-container .info-form .info-form-group input,
.first_row input,
.second_row input,
.third_row input,
.forth_row input,
.first_row_review input,
.second_row_review input,
.third_row_review input,
.forth_row_review input {
  width: 100%;
  padding: 13px 5px;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #b1aaef;
  font-size: 1.2rem;
  font-weight: 500;
  color: #877afd;
  transition: linear 0.2s;
}

/* Custom form row and Restaurant form input focus style */
.info-container .info-form .info-form-group input:focus,
.first_row input:focus,
.second_row input:focus,
.third_row input:focus,
.forth_row input:focus {
  outline: none;
  border-bottom: 2px solid var(--primary-color);
}

.first_row,
.second_row,
.forth_row,
.first_row_review,
.third_row_review {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.third_row {
  width: 100%;
  margin-top: 20px;
}
.row_input {
  width: 48%;
  margin-top: 20px;
}

.second_row_review,
.third_row_review,
.forth_row_review {
  background: #f4f5fe;
  padding: 0px 20px;
}

.third_row_review input {
  width: 48%;
}

.second_row_review input,
.third_row_review input,
.forth_row_review input {
  background-color: transparent;
  margin-bottom: 8px;
  padding-bottom: 5px;
  color: #5046af;
  font-weight: 600;
  font-size: 14px;
}

/* Place holder css */
.info-container .info-form .info-form-group input::-webkit-input-placeholder,
.first_row input::-webkit-input-placeholder,
.second_row input::-webkit-input-placeholder,
.third_row input::-webkit-input-placeholder,
.forth_row input::-webkit-input-placeholder {
  color: #c7c0ff;
  font-weight: 500;
  font-size: 18px;
}

/* -----------End---------------- */

/* ---- Googel address css --- */
.googel-location .MuiAutocomplete-root {
  width: 100%;
}
.restaurant_info_address .MuiInputBase-root .MuiInputBase-input {
  border-bottom: none !important;
  margin: 12px 0;
}
.restaurant_info_address .MuiInput-root:before {
  border-bottom: 2px solid #b1aaef !important;
}
.restaurant_info_address .MuiInput-root:after {
  border-bottom: 2px solid #b1aaef !important;
}
.address-component ::placeholder {
  color: #6955ff !important;
}
.contact_address_input .MuiFilledInput-root{
  padding: 0 !important;
}
/* --------End---------- */

.pricing_plan_box {
  margin: auto;
  margin-top: 15px;
  padding: 5px 15px;
  width: 40%;
  border-radius: 10px;
  box-shadow: 1px 0px 8px gray;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
.pricing_plan_box_heading {
  padding: 5px;
  color: #5046af;
  font-weight: 700;
  border-bottom: 2px solid #818cf8;
}

.pricing_plan_box_outlet,
.pricing_plan_box_integration,
.pricing_plan_box_chang-plan {
  padding: 5px;
  color: #8d80fa;
}
.pricing_plan_box_chang-plan {
  text-decoration: underline;
}
.pricing_plan_box_price {
  padding: 5px;
  width: fit-content;
  margin: auto;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 1px 0px 5px gray;
  color: #8d80fa;
}
.pricing_plan_box_chang-plan {
  font-size: 13px;
  cursor: pointer;
  padding-top: 10px;
}
.review_confirm_button {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.next_button {
  width: 100%;
  display: flex;
  justify-content: center;
  transition: linear 0.2s;
  padding-bottom: 2rem;
  margin-top: 1rem;
}
.back_next_restaurant {
  width: 100%;
  display: flex;
  justify-content: center;
  transition: linear 0.2s;
}

.info-container .info-form .info-form-inner h1,
.info-form-inner-small h1,
.custom-form h1 {
  margin-bottom: 35px !important;
  color: #818cf8;
  font-size: 2.3rem;
  text-align: center;
}
.personal-h1 {
  margin-bottom: 40px !important;
}
.rest-h1 {
  margin-bottom: 40px !important;
  margin-top: 10px !important;
}
.custom-form h1 {
  margin-top: 20px;
}

/* --------- Otp ----------- */
.otp-h1 {
  font-size: 2rem !important;
  margin-bottom: 10px !important;
}
.otp-form {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 1px 1px 6px gray;
  max-width: 500px;
  text-align: center;
  width: 100%;
}
/* OTP input styles */
.otp-container,
.email-otp-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.otp-input,
.email-otp-input {
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 16px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
  color: #877afd;
}
.mobile-otp p,
.email-otp p {
  margin: auto 10px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  color: #877afd;
}
.resend_link,
.disabled_reset_link {
  margin-top: 10px;
  cursor: pointer;
  color: #6776fd;
  font-weight: bolder;
  font-size: 14px;
}
.disabled_reset_link {
  color: rgba(0, 0, 0, 0.26);
}
.otp-input:focus,
.email-otp-input:focus {
  border-color: #877afd;
}
.valuewithinput {
  display: flex;
}
.editableinput,
.editableinput-disable {
  display: flex;
  border: 1px solid #877afd;
  border-radius: 5px;
  width: 70%;
  background: #877afd1f;
}
.editableinput-disable {
  background-color: white;
}
.editicon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #818cf8;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.editicon:hover {
  background-color: #818df831;
}
#verificationCode,
#emailverificationCode {
  width: 85%;
  padding: 8px;
  font-size: 14px;
  border: none;
  outline: none;
  color: #877afd;
  margin-left: 2px;
  background: transparent;
}
#verificationCode:focus,
#emailverificationCode:focus {
  border-color: #007bff;
}
.email-otp {
  margin-top: 25px;
}
.countdown-text {
  padding-top: 10px;
}

.error-message {
  color: #f74c4c;
  margin-top: 2px;
  padding-left: 5px;
}
.purchase_plan_popup {
  width: 500px;
}
.purchase_plan_popup .custom-title-class {
  font-size: 20px;
}
.purchase_plan_popup .swal2-icon {
  font-size: 14px;
}
@media screen and (min-width: 768px) {
  .info-container .onboarding {
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  .info-container .onboarding {
    display: none;
  }
  .info-container {
    min-height: 50vh;
    flex-direction: row;
    align-items: center;
  }
}
@media screen and (max-width: 550px) {
  .pricing_plan_box {
    width: 60%;
  }
}
@media screen and (max-width: 475px) {
  .first_row,
  .second_row,
  .forth_row,
  .third_row_review {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .first_row input,
  .second_row input,
  .third_row input,
  .forth_row input,
  .third_row_review input {
    width: 100%;
  }
  .row_input {
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .info-container .info-form .info-form-inner h1 {
    font-size: 2rem;
  }
  .info-container .info-form {
    padding: 20px;
  }
  .info-container .info-form-group {
    margin-bottom: 16px;
  }
  .otp-input,
  .email-otp-input {
    width: 30px;
    height: 30px;
  }
  .otp-h1 {
    font-size: 1.8rem !important;
  }
}
