.blog_page {
  min-height: calc(80vh - 80px);
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}

.blog_page h1 {
  text-align: center;
  color: #3e4d6c;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  font-size: 2.5rem;
}

.blog_slider {
  min-height: calc(50vh - 80px);
  width: 90%;
  margin: 40px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog_page .swiper-wrapper {
  padding-bottom: 5px;
  cursor: pointer;
}

.blog_page .swiper-slide {
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  /* border: 2px solid blue; */
}

.blog_page .swiper {
  width: 100%;
  margin: 0px auto;
  padding: 0 20px;
}

.cardContainer {
  border-radius: 10px !important;
  box-shadow: 1px 1px 6px gray !important;
}

.card_heading {
  font-weight: 500;
  font-size: 1.3rem;
}

.card_discription {
  text-align: justify;
  font-size: 1.1rem !important;
  margin-bottom: 20px;
}
.show_all_blog_button {
  display: flex;
  justify-content: center;
}
.show_all_blog_button a {
  text-decoration: none;
  color: white;
}
.show_all_blog_button button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  padding-left: 10px;
  font-size: 18px;
  border: none;
  color: white;
  border-radius: 30px;
  background-color: #818cf8;
  transition: background-color 0.3s;
  cursor: pointer;
}

.forward_arrow {
  margin-left: 10px;
  background: #ffffff66;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
@media screen and (max-width: 1400px) {
  .card_heading {
    font-size: 1.2rem;
  }

  .card_discription {
    font-size: 1rem !important;
  }
}
@media screen and (max-width: 1366px) {
  .cardContainer .card-img {
    height: 200px;
  }
}

@media screen and (max-height: 800px) {
  .blog_page h1 {
    height: 20px;
    margin-top: 30px;
  }

  .blog_slider {
    margin: 25px auto;
  }

  .card_content {
    padding: 5px 14px !important;
  }
  .cardContainer .card-img {
    height: 200px;
  }
}
@media screen and (max-height: 700px) {
  .blog_page h1 {
    height: 20px;
    margin-top: 30px;
  }
  .card_discription {
    margin-top: 5px !important;
  }
}
