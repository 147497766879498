 
::-webkit-scrollbar {
    width: 8px; 
    height: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgb(154, 137, 247);
    border-radius: 6px; 
    padding: 10px;
  }
#second-component{
transform: translateY(0%)
}
#first-component{
    transform: translateY(0%)
}
.option_item{
  color: #877AFD !important;
  font-weight: 600 !important;
}
.option_item:hover{
  background-color: #a0a8fd !important;
  color: white !important;
}
.main_homr_page{
  overflow-x: hidden;
}