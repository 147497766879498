.footer {
  background: #303030;
  color: white;
}

.footer_first_content {
  width: 90%;
  margin: auto;
  padding: 30px;
  display: grid;
  grid-template-areas: 'first second third';
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.footer_first_content h2 {
  color: #818cf8;
  margin-bottom: 10px;
}

.footer_description {
  grid-area: first;
}

.find_on_us {
  grid-area: second;
}

.footer_support {
  grid-area: third;
  display: flex;
  flex-direction: column;
}

.find_icons {
  display: flex;
}

.find_icons p {
  padding-left: 10px;
  cursor: pointer;
}

.support_content {
  display: flex;
  align-items: center;
}

.support_content p a {
  color: white;
  text-decoration: none;
  word-wrap: break-word;
  text-align: start;
}

span {
  margin-right: 5px;
}

.footer_link_content {
  width: 90%;
  margin: auto;
  padding: 0px 30px 30px 30px;
}

.footer_usefull_link {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer_usefull_link_grid {
  width: 100%;
  display: grid;
  grid-template-areas: 'first second third';
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-auto-flow: column;
  column-gap: 30px;
}

.footer_usefull_link p {
  margin-bottom: 5px;
  cursor: pointer;
}

.footer_usefull_link p:hover {
  color: #bac1ff;
}

.footer_usefull_link p a {
  text-decoration: none;
  color: white;
}

.footer_usefull_link a:hover {
  color: #bac1ff;
}

.footer_second_content {
  background: #242424;
  color: white;
  text-align: center;
  padding: 10px;
}

.footer_first_content p {
  font-size: 16px;
  text-align: start;
}

@media screen and (max-width: 870px) {
  .footer_first_content {
    grid-template-areas:
      'first'
      'second'
      'third';
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    text-align: center;
  }
  .find_icons {
    justify-content: center;
    margin-top: 10px;
  }

  .footer_support {
    align-items: center;
  }
  .usefull_header {
    text-align: center;
  }
}

@media screen and (max-width: 580px) {
  .footer_first_content {
    padding: 20px;
  }

  .footer_usefull_link_grid {
    grid-template-areas: 'first' 'second' 'third';
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    text-align: center;
  }

  .footer_usefull_link p {
    margin-bottom: 10px;
  }

  .find_icons {
    justify-content: center;
    margin-top: 10px;
  }
  .footer_support {
    align-items: center;
  }
  .usefull_header {
    text-align: center;
  }
}

@media screen and (max-height: 700px) {
  .footer {
    margin-top: 30px;
  }
  .usefull_header {
    text-align: center;
  }
}
