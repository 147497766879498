.product_featurs_page{
    margin-top: 80px;
    /* min-height: calc(100vh - 80px); */
    display: flex;
    flex-direction: column;
}

.product_featurs_page h1{
    padding-top: 20px;
    text-align: center;
    color: #3E4D6C;
    font-size: 2.5rem;
}
.sliders_and_peras{
    flex: 1;
    margin-top: 80px;
}
.product_features_video{
    width: 100%;
    margin-top: 50px;
    position: relative;
}
.text-overlay {
    position: absolute;
    width: 100%;
    background: linear-gradient(rgba(59, 59, 59, 0.63), rgba(134, 134, 134, 0.5));
    font-weight: bold;
    padding: 10px;
    color: white;
    text-align: left;
    height: 100vh;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  .text-overlay h1{
    text-align: left;
    height: fit-content;
    padding-left: 1rem;
  }
.product_features_video video{
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
.product_featurs_slider{
    width: 80%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
}
.left_side_of_slider{
    width: 60%;
}
.left_side_of_slider h1{
   text-align: left;
   color: #818CF8;
}
.left_side_of_slider p{
  font-size: 1.2rem;
  font-weight: 500;
  width: 85%;
  text-align: justify;
}

.right_slide_of_slider{
    text-align: right;
}
.product_features_pera_img{
    display: flex;
    width: 80%;
    margin: 30px auto;
}
.product_features_pera{
    flex: 1;
}
.product_features_pera p{
    font-size: 1.2rem;
    margin-top: 20px;
    font-weight: 600;
    text-align: justify;
}
.product_features_img{
   flex: 1;
   display: flex;
   align-items: center;
}
.product_features_img img{
    width: 70%;
    object-fit: cover;
    border-radius: 20px;
}
@media screen and (max-width:1600px){
    .product_features_pera p{
        font-size: 1.1rem;
    } 
    .product_features_img img{
        width: 90%;
    }
}
@media screen and (max-width:1400px){
    .product_features_pera p{
        font-size: 1rem;
    } 
}
@media screen and (max-height:900px){
    .sliders_and_peras{
        margin-top: 0px;
    }
 }