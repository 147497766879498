.single_page{
   margin-top: 80px;
}
.single_page_front_image{
    width: 100%;
    height: 50vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.single_page_heading h1{
    margin-top: 10px;
    text-align: center;
    color: #3E4D6C;
}
.single_page_discription{
    width: 80%;
    text-align: justify;
    margin: 20px auto;
}
.single_page_discription p {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 20px;
}
.single_page_discription span{
    color: #7d8afd;
    font-weight: 700;
}
.single_page_discription{
    display: flex;
    align-items: center;
}
.single_page_discription_pera{
    width: 50%;
}
.singlr_page_discription_image{
    width: 50%;
    text-align: right;
  
}
.singlr_page_discription_image img{
  width: 60%;
  border-radius: 20px;
}
@media screen and (max-width:1720px) {
    .single_page_discription p{
        font-size: 1.1rem;
    }
}
@media screen and (max-width:1600px) {
    .single_page_discription p{
        margin-top: 10px;
    }
}
@media screen and (max-width:1550px) {
    .single_page_discription
    {
        width: 85%;
    }

    .singlr_page_discription_image img{
        width: 70%;
  
      }
}
@media screen and (max-width:1400px) {
    .single_page_discription p{
        font-size: 1rem;
    }
}
@media screen and (max-width:1300px) {
    .single_page_discription p{
        font-size: 1rem;
    }
    .single_page_discription
    {
        width: 90%;
    }
}
@media screen and (max-width:1023px) {
    .single_page_discription p{
        font-size: 1rem;
    }
    .single_page_discription
    {
        width: 90%;
    }
    .single_page_discription{
        flex-direction: column;
    }
    .single_page_discription_pera{
        width: 80%;
    }
    .singlr_page_discription_image{
        margin-top: 20px;
        width: 90%;
        text-align: center;
    }
}