
@keyframes spin {
    from {
      transform: rotate(0);
    }
    to{
      transform: rotate(359deg);
    }
  }
  
  @keyframes spin3D {
    from {
      transform: rotate3d(.5,.5,.5, 360deg);
    }
    to{
      transform: rotate3d(0deg);
    }
  }
  
  @keyframes configure-clockwise {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(90deg);
    }
    50% {
      transform: rotate(180deg);
    }
    75% {
      transform: rotate(270deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes configure-xclockwise {
    0% {
      transform: rotate(45deg);
    }
    25% {
      transform: rotate(-45deg);
    }
    50% {
      transform: rotate(-135deg);
    }
    75% {
      transform: rotate(-225deg);
    }
    100% {
      transform: rotate(-315deg);
    }
  }
  
  @keyframes pulse {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .25;
      transform: scale(.75);
    }
  }
  
  /* PULSE BUBBLES */

  .loading_page {
    width:100%;
    height:572px;
    display: flex;
    justify-content: center;
    align-items: center;
}


  .spinner-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .pulse-container {
    margin-top: 30px;
    width: 120px;
    display: flex;
    justify-content:space-around;
    align-items: center;
  }
  
  .pulse-bubble {
    width: 15px;
    height:15px;
    border-radius: 50%;
    background-color: #7d7ffc;
  }
  
  .pulse-bubble-1 {
      animation: pulse .4s ease 0s infinite alternate;
  }
  .pulse-bubble-2 {
      animation: pulse .4s ease .2s infinite alternate;
  }
  .pulse-bubble-3 {
      animation: pulse .4s ease .4s infinite alternate;
  }
  
  