
.success_payment_page{
  margin-top: 80px;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.payment_text{
    min-height: 10vh;
    width: 70%;
    margin: 10px auto;
    display: flex;
    align-items: end;
    justify-content: center;
    text-align: center;
    font-size: 22px;
    font-weight: 600;
}
.payment_text span{
    color:#818CF8;
}
.payment_img , .fail_payment_img{
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.payment_img img{
    width: 80vw;
   height: 60vh;
}
.fail_payment_img img{
    width: 80vw;
    height: 60vh;
}
.try_again_link {
    cursor: pointer;
    padding-bottom: 2px;
    border-bottom: 1px solid #818CF8;
}
.payment_animation_container{
    display: flex;
    justify-content: center;
}
.payment_animation , .afterconfirm{
    width: 75% !important;
    height: 75%!important;
}

/* Media query of width */

@media screen and (max-width:1000px) {
    .payment_text{
        min-height: 20vh;
    }
    .payment_img , .fail_payment_img{
        min-height: 65vh;
    }
    .payment_text{
        font-size: 20px;
    }

}
@media screen and (max-width:900px) {
    .payment_text{
        font-size: 20px;
    }
}
@media screen and (max-width:800px) {
    .payment_text{
        min-height: 25vh;
    }
    .payment_img , .fail_payment_img{
        min-height: 60vh;
    }
    .payment_text{
        font-size: 19px;
    }
}
@media screen and (max-width:700px) {
    .payment_text{
        font-size: 18px;
    }
}
@media screen and (max-height:870px) {
    .payment_animation{
        width: 60% !important;
        height: 60%!important;
    }
    .afterconfirm{
        width: 43% !important;
    }
}
@media only screen and (orientation: portrait) {
    .payment_animation{
        width: 100% !important;
        height: 100%!important;
    }
    .afterconfirm{
        width: 100% !important;
    }
  }