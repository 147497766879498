.virtual_kitchen h1{
    text-align: center;
    font-size: 2.5rem;
    color: #3E4D6C;
}
.virtual_kitchen p {
    text-align: justify;
    width: 80%;
    font-size: 1.2rem;
    margin: auto;
    font-weight: 600;
}
.virtual_kitchen p span{
    color: #818CF8;
}
@media screen and (max-width:520px) {
    .virtual_kitchen h1{
        font-size: 2rem;  
    }
}