.section_page {
  margin-top: 80px;
  z-index: 50;
  background-attachment: fixed;
}

.my_slider {
  display: flex !important;
}

.section_top_content {
  display: flex;
}

.slide {
  display: flex;
  align-items: center;
  min-height: calc(100vh - 80px);
}

.section_info {
  padding-left: 10rem;
  width: 50%;
  color: black;
  font-size: 21px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.section_heddings {
  font-size: 2.2rem;
}

.section_heddings p {
  font-size: 1.4rem;
}
.section_heddings p span{
  visibility: hidden;
}
.section_heddings .heading_two {
  color: #818CF8;
}

.section_getstart {
  width: 50%;
}

.section_getstart_button {
  margin-top: 30px;
}

.section_getstart_button button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  padding-left: 10px;
  font-size: 20px;
  border: none;
  color: white;
  border-radius: 30px;
  background-color: #818CF8;
  transition: background-color 0.3s;
  cursor: pointer;
}

.forward_arrow {
  margin-left: 10px;
  background: #FFFFFF66;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.section_getstart_button button:hover {
  background-color: #6473f8;
}

.section_image {
  width: 50%;
  object-fit: contain;
  text-align: center;
}

.section_image img {
  width: 750px;
  height: auto;
}

/* CustomCarousel.css */
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 25px !important;
  color: #818CF8;
  font-weight: 800;
}

.swiper-pagination-bullet {
  height: 12px !important;
  width: 12px !important;
  border-radius: unset !important;
}

.swiper-pagination-bullet-active {
  background-color: #818CF8 !important;
}

/* Media Queries */
@media screen and (max-width:1750px) {
  .section_info {
    padding-left: 8rem;
  }

  .section_heddings {
    font-size: 2rem;
  }

  .section_image img {
    width: 650px;
  }
}

@media screen and (max-width:1630px) {
  .section_heddings {
    font-size: 1.8rem;
  }
  .section_getstart_button button{
    font-size: 18px;
  }
}

@media screen and (max-width:1605px) {
  .section_info {
    padding-left: 5rem;
  }

}

@media screen and (max-width:1528px) {
  .section_info {
    padding-left: 6rem;
  }
}

@media screen and (max-width:1500px) {
  .section_heddings {
    font-size: 1.6rem;
  }

  .section_image img {
    width: 550px;
  }
}

@media screen and (max-width:1370px) {

  .section_heddings p {
    font-size: 1.2rem;
  }

}

@media screen and (max-width:1300px) {

  .section_info {
    width: 60%;
  }
  .section_image img {
    width: 500px;
  }

  .section_getstart_button button {
    font-size: 18px;
  }
}

@media screen and (max-width:1225px) {
  .section_image {
    width: 50%;
  }
  .section_getstart_button button {
    font-size: 18px;
  }
}

@media screen and (max-width:1190px) {
  .section_info {
    padding-left: 4rem;
  }
}

@media screen and (max-width:1150px) {
  .section_heddings {
    font-size: 1.4rem;
  }

  .section_image img {
    width: 400px;
  }

  .section_getstart_button button {
    font-size: 16px;
  }
}

@media screen and (max-width:1025px) {
  .section_heddings {
    font-size: 1.2rem;
  }

  .section_image img {
    width: 380px;
  }
  .section_heddings p {
    font-size: 1.1rem;
  }
  .section_getstart_button button {
    font-size: 16px;
  }
}

@media screen and (max-width:900px) {
  .slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .section_info {
    padding-left: 0;
    width: 80%;
    margin: 60px auto;
  }

  .section_heddings {
    font-size: 1.6rem;
  }

  .section_image {
    width: fit-content;
    margin: auto;
  }

  .section_image img {
    width: 500px;
  }
}
@media screen and (max-width:747px) {
  .section_info {
    width: 90%;
  }
}

@media screen and (max-width:680px) {
  .section_heddings {
    font-size: 1.3rem;
  }

  .section_image img {
    width: 450px;
  }
  .section_getstart_button button {
    font-size: 14px;
  }
  .forward_arrow{
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width:580px) {
  .section_heddings {
    font-size: 1.2rem;
  }

  .section_image img {
    width: 400px;
  }
}

@media screen and (max-width:480px) {
  .section_heddings {
    font-size: 1rem;
  }
  .section_heddings p {
    font-size: 1rem;
  }
  .section_image img {
    width: 350px;
  }
}

@media screen and (max-width:380px) {
  .section_info {
    padding: 0px 10px;
    justify-content: center;
  }

  .section_image img {
    width: 300px;
  }
}