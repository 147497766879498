.faq_page {
    min-height: 100vh;
    padding-top: 8rem;
}

.faq_page h1 {
    text-align: center;
    color: #3E4D6C;
    font-size: 2.5rem;
    /* padding-bottom: 1rem; */
}

.faq_question_and_ans {
    width: 100%;
}

.question_and_ans {
    width: 70%;
    min-width: 40%;
    margin: auto;
    box-shadow: 1px 1px 6px gray;
    border-radius: 10px;
    padding: 10px 15px;
    margin-top: 15px;
}
.question_and_ans:last-child{
    margin-bottom: 10px;
}
.question_and_plusicon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.question_and_plusicon h3 {
    font-size: 21px;
}

.ans {
    font-weight: 600;
    font-size: 17px;
}

/* Media Query for width */
@media screen and (max-width:1600px) {
    .question_and_plusicon h3 {
        font-size: 20px;
    }
    .ans {
        font-size: 16px;
    }
}

@media screen and (max-width:1500px) {
    .question_and_plusicon h3 {
        font-size: 18px;
    }
    .ans {
        font-size: 15px;
    }
}

@media screen and (max-width:960px) {
    .question_and_plusicon h3 {
        font-size: 18px;
    }
    .question_and_ans{
        width: 80%;
    }
    .ans {
        font-size: 15px;
    }
}
@media screen and (max-width:670px) {
    .faq_page {
        padding-top: 8rem;
    }

    .faq_page h1 {
        padding-bottom: 2.5rem;
    }
}

@media screen and (max-width:560px) {

    .faq_page h1 {
        padding-bottom: 2.3rem;
    }

    .question_and_ans {
        font-size: 0.9rem;
    }
}
@media screen and (max-width:500px) {
    .question_and_plusicon h3 {
        font-size: 16px;
    }
    .ans {
        font-size: 14px;
    }
}
/* Media Query for height */
@media screen and (max-height:900px) {
    .faq_page {
        padding-top: 8rem;
    }

    .faq_page h1 {
        padding-bottom: 2rem;
    }
}

@media screen and (max-height:820px) {
    .faq_page {
        padding-top: 7rem;
    }

    .faq_page h1 {
        font-size: 2.3rem;
        padding-bottom: 1.5rem;
    }
    .question_and_ans {
        padding: 6px 15px;

    }
}

@media screen and (max-height:733px) {
    .faq_page {
        padding-top: 7.5rem;
    }

    .faq_page h1 {
        font-size: 2rem;
        padding-bottom: 0rem;
    }

    .question_and_ans {
        padding: 4px 15px;
    }
}
@media screen and (max-height:665px) {
    .faq_page {
        padding-top: 5.8rem;
    }

}

@media screen and (max-width:670px) {
    .faq_page {
        padding-top: 8rem;
    }

    .faq_page h1 {
        padding-bottom: 2.5rem;
    }
}

@media screen and (max-width:560px) {

    .faq_page h1 {
        padding-bottom: 2.3rem;
    }

    .question_and_ans {
        font-size: 0.9rem;
    }
}