.privacy_policy_container{
    width: 60%;
    margin: 100px auto;
}
.privacy_policy_container h1 , .privacy_policy_container h2{
 padding-bottom: 1rem;
}

.privacy_policy_container h3{
    padding-bottom: 0.3rem;
}
.privacy_policy_container ul
{
    padding-bottom: 1rem;
}

.privacy_policy_container p ,.privacy_policy_container ul li , .privacy_address{
    /* text-align: justify; */
    font-size: 1.1rem;
    font-weight: 350;
    line-height: 1.6rem;
    padding-bottom: 1.5rem;
}
.privacy_policy_container ul li{
    padding-bottom: 1rem;
}
.privacy_policy_container hr{
   margin-top: 1.5rem;
   margin-bottom: 1.8rem;
}

@media screen and (max-width:1400px) {
    .privacy_policy_container{
        width: 70%;
    }
    .privacy_policy_container p ,.privacy_policy_container ul li , .privacy_address{
        font-size: 1rem;
    }
}
@media screen and (max-width:805px) {
    .privacy_policy_container h1{
        font-size: 1.6rem;
       }
}
@media screen and (max-width:700px) {
    .privacy_policy_container h1{
        font-size: 1.4rem;
       }
}