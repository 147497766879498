.about_menu_page {
    min-height: calc(100vh - 80px);
    margin-top: 80px;
    display: flex;
    align-items: center;
}

.about_menu_hedading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about_menu_hedading h1 {
    color: #3E4D6C;
    font-size: 2.5rem;
    text-align: center;
}

.about_menu_text_and_image {
    min-height: calc(80vh);
    display: flex;
    flex-direction: column;
}

.about_menu_text {
    width: 70%;
    margin: auto;
    text-align: justify;
    font-size: 21px;
    font-weight: 600;
}
.about_menu_text span{
    color:#818CF8;
}
.about_menu_text p {
    margin-top: 15px;
}

.about_menu_image {
    text-align: center;
}

.about_menu_image img {
    width: 70vw;
    height: 50vh;
} 
@media screen and (max-width:1700px) {
    .about_menu_hedading h1{
        font-size: 2.3rem;
    }
    .about_menu_text{
        font-size: 20px; 
    }
    .about_menu_image img{
        height: 45vh;
    }
  }

  @media screen and (max-width:1500px) {
    .about_menu_text{
        font-size: 19px;   
    }
  }
  @media screen and (max-width:1400px) {
    .about_menu_text{
        font-size: 18px; 
    }
    .about_menu_hedading h1{
        font-size: 2.1rem;
    }
  }
  @media screen and (max-width:800px) {
    .about_menu_text{
       width: 80%; 
       font-size: 17px;
    }
    .about_menu_image img{
        width: 80vw;
    }
}
@media screen and (max-width:670px) {
    .about_menu_text{
       width: 80%; 
       font-size: 16px;
    }
    .about_menu_image img{
       height: 40vh;
    }
}
@media screen and (max-width:670px) {
    .about_menu_text{
       width: 90%; 
       font-size: 16px;
    }
    .about_menu_image img{
       height: 40vh;
    }
}
@media screen and (max-width:570px) {
    .about_menu_text{
       width: 90%; 
    }
    .about_menu_image img{
       height: 40vh;
    }
}
@media screen and (max-width:520px) {
    .about_menu_hedading h1{
        font-size: 2rem;
    }
}
@media screen and (max-width:450px) {
    .about_menu_text
    {
       font-size: 14px;
    }
  } 
