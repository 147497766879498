*{
  margin: 0;
  font-family: "Inter var" !important;
  font-feature-settings: "salt" !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
transition: background-color 9999s ease-in-out 0s !important;
-webkit-text-fill-color: #877AFD !important;
}
body{
  background: #F8F8F8;

}