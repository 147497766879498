.thirdparties_order_integration_page {
    padding-top: 100px;
    padding-bottom: 20px;
    background-color: #f1f5f9;
}

.solution_banner {
    padding-top: 7rem;
    padding-bottom: 10rem;
    margin: 0px 50px;
    border-radius: 30px;
    margin-bottom: 50px;
    /* background-image: url(/assets/img/demo/engine-bg.png); */
    background-color: #818cf8;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}

.solution_banner_container {
    padding: 0px 3rem !important;
}

.partner-section h4,
.comparison_integration_head h4,
.all_in_one_place {
    font-size: 2.5rem;
    color: #363d47;
}

.inquiry_field > .MuiInputBase-root > input:-webkit-autofill,
.inquiry_field input:-webkit-autofill:hover,
.inquiry_field input:-webkit-autofill:focus,
.inquiry_field input:-webkit-autofill:active {
-webkit-text-fill-color: black !important;
}