.blog_page_content {
  margin-top: 80px;
}
.blog_page_slider {
  min-height: 60vh;
}
.blog_back_img_and_content {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/public/assets/img/blog3.svg");
  min-height: 80vh;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 5rem;
}
.blog_page_content_heading {
  width: 35%;
}
.read_artical_button button {
  display: flex;
  align-items: center;
  background-color: #818cf8;
  cursor: pointer;
  font-size: 18px;
  border: none;
  color: white;
  border-radius: 30px;
  padding: 5px;
  padding-left: 10px;
  margin-top: 15px;
  transition: background-color 0.3s;
}
.read_artical_button button:hover {
  background-color: #6473f8;
}
.blogs {
  width: 90%;
  margin: 50px auto;
  display: flex;
  justify-content: center;
  flex-flow: wrap;
}

.blogs_container {
  margin-top: 30px;
  margin-right: 10px;
  width: 400px;
}
.profile_and_name {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.profile_and_name img {
  height: 40px;
  width: 40px;
}

.blog_detail_title_and_back_img{
 min-height: 40vh;
 background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/public/assets/img/blog2.svg');
 background-repeat: no-repeat;
 background-size: cover;
 display: flex;
 justify-content: center;
 align-items: center;
 color: white;
}
.blog_details{
  width: 65%;
  margin: 20px auto;
  text-align: justify;
  font-weight: 500;
  font-size: 1.2rem;
}
.blog_details p { 
  margin-top: 30px;
}
.blog_detail_img{
  text-align: center;
  margin: 25px;
}
.blog_detail_img img{
  width: 65%;
}
.faith_fully{
  margin-top: 20px;
}
@media screen and (max-width: 1822px) {
  .blogs {
    width: 80%;
  }
}
@media screen and (max-width: 1550px) {
  .blogs {
    width: 90%;
  }
  .blog_page_content_heading {
    width: 45%;
  }
  .blog_details{
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 1400px) {
  .blogs {
    width: 95%;
  }
}
@media screen and (max-width: 1300px) {
  .blogs {
    width: 70%;
  }
}
@media screen and (max-width: 1200px) {
  .blog_page_content_heading {
    width: 55%;
  }
  .blogs {
    width: 75%;
  }
}
@media screen and (max-width: 1100px) {
  .blogs {
    width: 80%;
  }
}
@media screen and (max-width: 1050px) {
  .blogs {
    width: 85%;
  }
  .blog_detail_img img{
    width: 80%;
  }
}
@media screen and (max-width: 1000px) {
  .blog_page_content_heading {
    width: 65%;
  }
  .blogs {
    width: 90%;
  }
  .blog_details{
    width: 75%;
    font-size: 1rem;
  }
}
@media screen and (max-width: 920px) {
  .blog_page_content_heading {
    width: 75%;
  }
  .blogs {
    justify-content: space-around;
  }
  .blog_detail_img img{
    width: 90%;
  }
  .blog_detail_title_and_back_img h1{
    padding-left: 1rem;
  }
}
@media screen and (max-width: 750px) {
  .blog_page_content_heading {
    width: 85%;
  }
  .blog_detail_img img{
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .blog_page_content_heading {
    width: 95%;
  }
  .blog_details{
    width: 85%;
  }
}
@media screen and (max-width: 450px) {
  .blog_details{
    width: 90%;
  }
}
@media screen and (max-height:800px) {
  .blog_back_img_and_content{
    min-height:calc(100vh - 80px)
  }
  .blog_page_slider {
    min-height:calc(100vh - 80px)
  }
}
