.terms_container{
    width: 60%;
    margin: 100px auto;
}
.terms_container h1 , .terms_container h2{
    padding-bottom: 1rem;
 }
 .terms_container p ,.terms_container ul li , .terms_container ol li,.terms_agent {
    /* text-align: justify; */
    font-size: 1.1rem;
    font-weight: 350;
    line-height: 1.6rem;
    padding-bottom: 1.5rem;
}
.terms_container a{
    color: #818CF8;
    text-decoration: none;
}
@media screen and (max-width:1400px) {
    .terms_container{
        width: 70%;
    }
    .terms_container p ,.terms_container ul li , .terms_agent , .terms_container ol li{
        font-size: 1rem;
    }
}
@media screen and (max-width:805px) {
    .terms_container h1{
        font-size: 1.6rem;
       }
}
@media screen and (max-width:700px) {
    .terms_container h1{
        font-size: 1.4rem;
       }
}