.pos_int_page {
  margin-top: 80px;
  /* min-height: calc(100vh - 80px); */
  display: flex;
  flex-direction: column;
}
.pos_int_heading {
  padding-top: 20px;
  text-align: center;
}
.pos_int_heading h1 {
  font-size: 2.5rem;
  color: #3e4d6c;
}
.pos_peras {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pos_pera_1,
.pos_pera_2,
.pos_pera_3 {
  width: 80%;
  margin: auto;
  text-align: justify;
  font-weight: 600;
  font-size: 1.2rem;
}
.pos_pera_2,
.pos_pera_3 {
  display: flex;
  align-items: center;
}
.pera {
  flex: 1;
}
.pera p {
  margin-top: 40px;
}
.img {
  flex: 1;
  text-align: right;
}
.img img {
  width: 80%;
}
.pos_int_faq {
  margin-bottom: 20px;
}
.pos_int_faq h1 {
  margin: 30px;
  text-align: center;
}

.order_managment {
  width: 60%;
  margin: 0px auto 100px auto;
}
.order_managment h3 {
  margin-top: 30px;
  font-size: 1.6rem;
  font-weight: bolder;
}

.order_managment p {
  font-size: 1.2rem;
  font-weight: 500;
}

.order_managment ul li {
  font-size: 1.2rem;
  font-weight: 500;
}

@media screen and (max-width: 1500px) {
  .pos_pera_1,
  .pos_pera_2,
  .pos_pera_3 {
    font-size: 1.1rem;
  }
  .img img {
    width: 90%;
  }
}
@media screen and (max-width: 1400px) {
  .pos_pera_1,
  .pos_pera_2,
  .pos_pera_3 {
    font-size: 1rem;
  }
  .order_managment p {
    font-size: 1rem;
  }
}
@media screen and (max-height: 850px) {
  .img img {
    width: 80%;
  }
}
