.brand_animation_container{
    margin-top: 80px;
    padding: 0 50px;
}
.brand_animation_container h1{  
    font-size: 2.5rem;
    color: #3E4D6C;
    text-align: center;
}
.brands_logos{
    width: 80%;
    margin: 35px auto;
    display: flex;
    justify-content: space-around;
}
.brands_logos div img{
    width: 150px;
    height: 150px;
}
@media screen and (max-width:1750px) {
    .brand_animation_container h1{
        font-size: 2.3rem;
    }
    .brands_logos{
        width: 90%;
    }
}
@media screen and (max-width:1500px) {
    .brand_animation_container h1{
        font-size: 2.3rem;
    }
    .brands_logos div img{
        width: 130px;
        height: 130px;
    }
}
@media screen and (max-width:1400px) {
    .brand_animation_container h1{
        font-size: 2.1rem;
    }
}
@media screen and (max-width:1200px) {
    .brands_logos div img{
        width: 120px;
        height: 120px;
    }
}
@media screen and (max-width:1100px) {
    .brands_logos{
        width: 95%;
    }
}
@media screen and (max-width:1150px) {
    .brands_logos div img{
        width: 100px;
        height: 100px;
    }
}
@media screen and (max-width:900px) {
    .brands_logos div img{
        width: 80px;
        height: 80px;
    }
}
@media screen and (max-width:750px) {
    .brand_animation_container{
        padding: 0 10px;
    }
    .brands_logos div img{
        width: 60px;
        height: 60px;
    }
    .brands_logos div{
        padding-left: 10px;
    }
    .brands_logos{
        width: 100%;
        overflow-x: scroll;
    }
}
@media screen and (max-height:800px) {
    .brand_animation_container{
      margin-top: 50px;
    }
  }