.contact_page{
    margin-top: 80px;
}
.contact_form{
    margin:auto;
    width: 800px;
    margin-top: 60px;
}
.contact_first_block{
  width: 800px;
  margin: auto;
}
.input-field .MuiInputBase-root input{
   padding: 16px !important;
}
.input-field .MuiInputBase-root::before{
    border: none !important;
}
.input-field .MuiInputBase-root::after{
    border: none !important;
}
.submit_button , .submit_button_disable{
    padding: 15px 50px !important;
    background-color: #818CF8 !important;
    border-radius: 25px !important;
    color: white !important;
}
.submit_button_disable{
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
    pointer-events: none;
}
.MuiFormHelperText-root {
    color: red !important;
}

.contact_second_block{
    width: 800px;
    margin: 50px auto;
    background-color: #f4f4f4;
    padding: 40px;
}
.contact_second_block span{
    color: #818CF8;
    cursor: pointer;
}
.contact_form input:-webkit-autofill,
.contact_form input:-webkit-autofill:hover,
.contact_form input:-webkit-autofill:focus,
.contact_form input:-webkit-autofill:active {
transition: background-color 9999s ease-in-out 0s !important;
-webkit-text-fill-color: black !important;
}

/* Apply media queries */

@media screen and (max-width: 1200px){
    .contact_first_block{
        width: 600px;
    }
    .contact_form{
        width: 600px;
    }
    .contact_second_block
    {
        width: 600px;
    }
}
@media screen and (max-width: 700px){
    .contact_first_block h3{
      font-size: 2.2rem;
    }
    .contact_first_block{
        width: 500px;
    }
    .contact_form{
        width: 500px;
    }
    .contact_second_block
    {
        width: 500px;
    }
}
@media screen and (max-width: 540px){
    .contact_first_block{
        width: 400px;
    }
    .contact_form{
        width: 400px;
    }
    .contact_second_block
    {
        width: 400px;
    }
}
@media screen and (max-width: 445px){
    .contact_first_block{
        width: 350px;
    }
    .contact_form{
        width: 350px;
    }
    .contact_second_block
    {
        width:350px;
    }
}
@media screen and (max-width: 380px){
    .contact_first_block{
        width: 300px;
    }
    .contact_form{
        width: 300px;
    }
    .contact_second_block
    {
        width:300px;
    }
}