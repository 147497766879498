.pos_int_page {
    margin-top: 80px;
    /* min-height: calc(100vh - 80px); */
    display: flex;
    flex-direction: column;
}

.pos_int_heading {
    padding-top: 20px;
    text-align: center;

}

.pos_int_heading h1 {
    font-size: 2.5rem;
    color: #3E4D6C;
}

.pos_peras {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pos_pera_1,
.pos_pera_2,
.pos_pera_3 {
    width: 80%;
    margin: auto;
    text-align: justify;
    font-weight: 600;
    font-size: 1.2rem;
}

.pos_pera_2,
.pos_pera_3 {
    display: flex;
    align-items: center;
}

.pera {
    flex: 1;
}

.pera p {
    margin-top: 40px;
}

.img {
    flex: 1;
    text-align: right;

}

.img img {
    width: 80%;
}

.pos_int_faq {
    margin-bottom: 20px;
}

.pos_int_faq h1 {
    margin: 30px;
    text-align: center;
}

.clover_line {
    width: 90%;
    margin: 80px auto;
    text-align: center;
    /* background-image: url('/public/assets/img/Printhooks_banner.png'); */
    height: 250px;
    /* background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center; */
    position: relative;
    cursor: pointer;
    border-radius: 20px;
}

.clover_line:hover {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.clover_line img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.clover_line .pos_line_box {
    color: white;
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 50%;
    width: 1200px;
    text-align: start;
    margin-left: 1.5rem;
}

.pos_line_box .pos_line_text {
    font-size: 1.4rem;
    font-weight: 600;
}

@media screen and (max-width:1685px) {
    .clover_line .pos_line_box {
        width: 1000px;
        text-align: center;
        justify-content: center;
    }
    
}

@media screen and (max-width:1585px) {
    .clover_line .pos_line_box {
        width: 900px;
    }
}
@media screen and (max-width:1385px) {
    .clover_line .pos_line_box {
        width: 800px;
    }
}

@media screen and (max-width:1285px) {
    .clover_line .pos_line_box {
        width: 700px;
    }
}
@media screen and (max-width:1185px) {
    .clover_line .pos_line_box {
        width: 600px;
    }
    .clover_line .pos_line_text{
        font-size: 1.2rem;
    }
}
@media screen and (max-width:1085px) {
    .clover_line .pos_line_box {
        width: 500px;
    }
}
@media screen and (max-width:985px) {
    .clover_line .pos_line_box {
        width: 400px;
    }
}
@media screen and (max-width:885px) {
    .clover_line .pos_line_box {
        top: 40%;
    }
}
@media screen and (max-width:785px) {
    .clover_line .pos_line_box {
        width: 300px;
    }
}
@media screen and (max-width:600px) {
    .clover_line{
        width: 80%;
        height: 550px;
    }
    .clover_line .pos_line_box {
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%);
    }
}
@media screen and (max-width:1500px) {

    .pos_pera_1,
    .pos_pera_2,
    .pos_pera_3 {
        font-size: 1.1rem;
    }

    .img img {
        width: 90%;
    }
}

@media screen and (max-width:1400px) {

    .pos_pera_1,
    .pos_pera_2,
    .pos_pera_3 {
        font-size: 1rem;
    }
}

@media screen and (max-height:850px) {
    .img img {
        width: 80%;
    }
}