
.pricing_page {
  min-height: calc(100vh - 80px);
  margin-top: 80px;
  display: flex;
  flex-direction: column;
}

.pricing_page h1 {
  text-align: center;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.3rem;
  margin-top: 50px;
  color: #3E4D6C;

}

.pricing_card_and_info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.pricing_container {
  width: 80%;
  margin: auto;
  display: flex;
}

/* Left pricing css */

.pricing_left {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: auto 0;
}

.pricing_left_toggle {
  margin: auto;
  color: black;
  background-color: white;
  display: flex;
  justify-content: space-between;
  background-color: white;
  width: fit-content;
  padding: 2px;
  border-radius: 25px;
  border: 1px solid #9C9DB9;
  font-weight: 600;
}

.toggle_back_color {
  background: #818CF8;
  color: white;
  font-weight: 600;
}

.toggle-left,
.toggle-right {
  padding: 12px 20px;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 550;
  transition: background-color 0.3s ease;
}

.single_pricing_card {
  width: 80%;
  border: 1px solid #9C9DB9;
  margin: 25px auto;
  height: 100px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.single_pricing_card:hover {
  transform: scale(1.1);
}

.checkbox_and_planname {
  display: flex;
  align-items: center;
}

.checkbox_and_planname h2 {
  font-size: 1.3rem;
}

.card_price {
  display: flex;
}

.doler_symbol {
  font-size: 1.3rem;
  margin-top: 10px;
  font-weight: 600;
}

.actual_price {
  font-size: 2.5rem;
}

.custom_price {
  font-size: 1.5rem;
  font-weight: 600;
}

.card_active {
  background: #818CF8;
  color: white;
  transition: background 0.5s ease;
}

.card_active:hover {
  transform: scale(1);
}

.month_year {
  font-size: 1.3rem;
  display: flex;
  align-items: flex-end;
  font-weight: 600;
}

.plan_name button {
  background: #B3BAFB4A;
  padding: 8px 10px;
  margin-top: 5px;
  font-weight: 500;
  border: none;
  color: #3E4D6C;
  border-radius: 20px;
}
.tailored_pricing{
  text-align: center;
}
.tailored_pricing button{
  background-color: #3E4D6C;
  color: white;
  padding: 10px 50px;
  font-size: 1.2rem;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.tailored_pricing button:hover{
  background-color: #4b5872;
}
/* Right pricing css */

.pricing_right {
  background-color: #f1f1f1d8;
  border-radius: 12px;
  width: 45%;
  border: 1px solid #9C9DB9;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.subscrip_button {
  text-align: center;
  padding-bottom: 10px;
}

.subscrip_button button,
.disabled_sub_button {
  padding: 10px 25px;
  font-size: 1.2rem;
  border-radius: 15px;
  border: none;
  color: white;
  background: #818CF8;
  cursor: pointer;
  font-weight: 600;
}

.disabled_sub_button {
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12);
}

.subscrip_button button:hover {
  background-color: #6473f8;
}

.pricing_description {
  width: 90%;
  font-size: 1.1rem;
  font-weight: 500;
}
#price_detail span{
  color: #818CF8;
  font-weight: 600;
}
.pricing_description ul{
  padding-left: 3rem;
}
.pricing_description ul h2{
  margin-bottom: 10px;
  text-align: center;
  color: #3E4D6C;
}
.pricing_description ul li{
  list-style: none;
  padding-bottom: 10px;
}
.swal2-container {
  z-index: 99999 !important;
}
.no_plan_image{
  display: flex;
  align-items: center;
  justify-content: center;
}
.no_plan_image img{
  padding: 0 20px;
  min-width: 800px;
  object-fit: contain;
}
/* Media quiries */

/* Left or side reponsive */
@media screen and (max-width:1700px) {
  .pricing_container {
    width: 90%;
  }
}
@media screen and (max-width:1500px) {
  .pricing_container {
    width: 100%;
    /* min-width: 1150px; */
  }
}
@media screen and (max-width:1270px) {
  .pricing_container {
    width: 100%;
  }

  /* .checkbox_and_planname h2 {
    font-size: 1.4rem;
  } */

  .pricing_description {
    font-size: 1.2rem;
  }
}

@media screen and (max-width:1100px) {
  .single_pricing_card {
    padding: 0 10px;
  }
  .pricing_description ul{
    padding-left: 2rem;
  }
}

@media screen and (max-width:1023px) {
  .pricing_container {
    flex-direction: column;
    align-items: center;
    min-width: unset;
  }

  .pricing_page h1 {
    height: 60px;
    margin-top: 20px;
  }

  .pricing_card_and_info {
    margin-top: 10px;
  }
  .pricing_left{
    width: 100%;
  }
  .single_pricing_card{
    width: 60%;
    margin: 15px auto;
    height: 90px;
  }
  .checkbox_and_planname h2 {
    font-size: 1.3rem;
  }
  .actual_price {
    font-size: 2.5rem;
  }
  .plan_name button {
    padding: 5px 10px;
    font-size: 0.8rem;
  }
  .pricing_right{
    width: 60%;
    margin-top: 1rem;
    padding-top: 1rem;
    min-height: 300px;
  }
  .pricing_description{
    padding-top: 0rem;
    font-size: 1.1rem;
  }
}
@media screen and (max-width:800px) {

  .single_pricing_card{
    width: 70%;
    height: 80px;
  }
  .checkbox_and_planname h2 {
    font-size: 1.2rem;
  }
  .actual_price {
    font-size: 2.5rem;
  }
  .pricing_right{
    width: 70%;
  }
}

@media screen and (max-width:700px) {

  .single_pricing_card{
    width: 80%;
    height: 80px;
  }
  .change_pricing{
    width: 100%;
  }
  .checkbox_and_planname h2 {
    font-size: 1.2rem;
  }
  .actual_price {
    font-size: 2.5rem;
  }
  .pricing_right{
    width: 80%;
  }
}
@media screen and (max-width:520px) {
  
.pricing_page h1{
  font-size: 2rem;
}

  .single_pricing_card{
    width: 85%;
  }
  .change_pricing{
    width: 100%;
  }
  .checkbox_and_planname h2 {
    font-size: 1.1rem;
  }
  .actual_price {
    font-size: 2.1rem;
  }
  .month_year{
    font-size: 1.1rem;
  }
  .pricing_right{
    width: 85%;
  }
  .pricing_description{
    font-size: 1rem;
  }
  .doler_symbol {
    margin-top: 5px;
  }
}
@media screen and (max-width:475px) {
  .change_pricing .checkbox_and_planname h2
  {
    font-size: 1rem;
  }
  .change_pricing .actual_price{
    font-size: 1.6rem;
  }
  .change_pricing .month_year{
   font-size: 1rem;
  }
  .doler_symbol {
    font-size: 0.8rem;
  }
}
@media screen and (max-width:440px) {
  .single_pricing_card{
    width: 90%;
  }
  .change_pricing{
    width: 100%;
  }
  .actual_price {
    font-size: 1.8rem;
  }
  .month_year{
    font-size: 1rem;
  }
  .pricing_right{
    width: 90%;
  }
}
@media screen and (max-width:420px) {
  .single_pricing_card{
    width: 95%;
  }
  .change_pricing{
    width: 100%;
  }
  .actual_price {
    font-size: 1.6rem;
  }
  .month_year{
    font-size: 1rem;
  }
  .pricing_right{
    width: 95%;
  }
  .doler_symbol {
    font-size: 1rem;
  }
  .checkbox_and_planname svg{
    font-size: 25px !important;
  }
  .change_pricing .checkbox_and_planname h2
  {
    font-size: 0.9rem;
  }
  .change_pricing .actual_price{
    font-size: 1.3rem;
  }
  .change_pricing .month_year{
   font-size: 0.8rem;
  }
  .doler_symbol {
    font-size: 0.6rem;
  }
}

/* Set Height according media query */

@media (orientation: landscape) and (max-height:900px) {
  .single_pricing_card {
    height: 90px;
  }

  .pricing_card_and_info {
    margin-top: 0px;
    flex-grow: 1;
  }

  .pricing_page h1 {
    margin-top: 0px;
    font-size: 2.1rem;
  }
}

@media (orientation: landscape) and (max-height:760px) {
  .single_pricing_card {
    margin: 15px auto;
  }

  .checkbox_and_planname h2 {
    font-size: 1.3rem;
  }

  .actual_price {
    font-size: 2.5rem;
  }

  .thir_parties_logo img {
    height: 50px;
    width: 50px;
    padding-left: 10px;
  }

  .pricing_description {
    font-size: 1.1rem;
  }
  .pricing_description ul li{
    padding-bottom: 5px;
  }
  .pricing_description ul h2{
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .subscrip_button button,
.disabled_sub_button {
  padding: 10px 25px;
  font-size: 1.1rem;
}
}

@media (orientation: landscape) and (max-height:700px) {
  .single_pricing_card {
    height: 85px;
  }

  .checkbox_and_planname h2 {
    font-size: 1.1rem;
  }
  
  .actual_price {
    font-size: 2.3rem;
  }
 .single_pricing_card{
   height: 75px;
 }
  .pricing_description{
    font-size: 1rem; 
  }
}

/* For No plan image media query */
@media screen and (max-height:930px) {
  .no_plan_image img{
    min-width: 700px;
  }
}
@media screen and (max-height:730px) {
  .no_plan_image img{
    min-width: 600px;
  }
}
@media (orientation: portrait) and (max-width:800px)
{
  .no_plan_image img{
    min-width: 450px;
  }
}