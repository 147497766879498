.image-slider-container {
    min-height: calc(100vh - 80px);
    width: 100%;
    background: #383A3E;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text_and_image{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left_text p{
    font-size: 1.4rem;
    cursor: pointer;
    font-weight: 500;
    padding-left: 15px;
    transition: transform 0.3s ease;
  }
  .left_text p > span {
    color: #818CF8;
    font-weight: 700;
  }
  .left_text p:hover{
    transform: scale(1.04);
  }
  .peragraph_logo{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  .right_imag{
    width: 40%;
  }

  .right_imag img{
   width: 100%;
  }
  @media screen and (max-width:1600px){
    .left_text p{
      font-size: 1.5rem;
    }
  }
  @media screen and (max-width:1500px){
    .left_text p{
      font-size: 1.4rem;
    }
  }
  @media screen and (max-width:1400px){

    .left_text p{
      font-size: 1.2rem;
    }
  }
  @media screen and (max-width:1200px){
    .text_and_image{
      width: 90%;
    }
    .left_text p{
      font-size: 1.2rem;
    }
    .peragraph_logo img{
      height: 50px;
      width: 50px;
    }
  }
  @media screen and (max-width:1023px){
    .text_and_image{
      padding: 1rem;
      flex-direction: column;
      justify-content: space-around;
    }
    .left_text p{
      font-size: 1.2rem;
    }
    .right_imag{
      width: 60%;
    }
  }
  @media screen and (max-width:800px){
    .left_text p{
      font-size: 1.1rem;
    }
    .right_imag{
      margin-top: 30px;
      width: 60%;
    }
  }
  @media screen and (max-width:700px){
    .right_imag{
      width: 70%;
    }
  }
  @media screen and (max-width:600px){
    .right_imag{
      width: 80%;
    }
  }
  @media screen and (max-width:550px){
    .left_text p{
      font-size: 1rem;
    }
  }
  @media screen and (max-height:800px) {
    .image-slider-container{
      margin-top: 40px;
    }
  }